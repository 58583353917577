<template>
  <v-btn icon @click="clickButton($event)">
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class IconButton extends Vue {
  @Prop({ default: 'mdi-plus' }) readonly icon!: string;

  clickButton(event: MouseEvent) {
    event.stopPropagation();
    this.$emit('click');
  }
}
</script>
