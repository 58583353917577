<template>
  <v-footer color="white">
    <v-container fluid id="footer-container">
      <v-row>
        <v-col class="footer-image-box" v-bind="$footerImageColWidth">
          <v-row class="footer-image-box-text">
            <v-col cols="12">
              <vlaanderen-logo class="footer-image" />
            </v-col>
          </v-row>
        </v-col>
        <v-col v-bind="$footerTextColWidth">
          <v-row>
            <v-col cols="12">
              <strong
                >VEKA - {{ $t('app.title-main') }} - Versie:
                {{ $store.getters.appVersion }}</strong
              >
            </v-col>

            <v-col cols="12">
              <article class="d-flex flex-column flex-md-row">
                <footer-link
                  href="https://www.vlaanderen.be/veka" target="_blank"
                  content="Over VEKA"
                />
                <separator/>
                <footer-link
                  :href="`${BASE_URL}pdfs/Disclaimer.pdf`"
                  content="Disclaimer"
                />
                <separator/>
                <footer-link
                  href="https://www.vlaanderen.be/veka/privacyverklaring"
                  content="Privacyverklaring"
                />
                <separator/>
                <footer-link
                  content='Cookieverklaring'
                  href="https://www.vlaanderen.be/over-vlaanderenbe/cookieverklaring-vlaanderenbe"
                />
              </article>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import VlaanderenLogo from '@/components/layout/VlaanderenLogo.vue';
import FooterLink from '@/components/links/FooterLink.vue';
import Separator from '@/components/Separator.vue';

@Component({
  components: { FooterLink, VlaanderenLogo, Separator },
})
export default class Footer extends Vue {
  BASE_URL = process.env.BASE_URL;
}
</script>
