<template>
  <v-dialog persistent max-width="700px" :value="value">
    <v-card>
      <v-card-title class="headline"> {{ dialogTitle }}</v-card-title>
      <v-card-text>{{ dialogContent }}</v-card-text>
      <v-card-actions>
        <wizard-button-group
          :next-text="deleteText ? deleteText : this.$t('acties.verwijderen')"
          :back-text="this.$t('acties.annuleren')"
          :name="'delete-dialoog-verwijderen'"
          @next="verwijder"
          @back="annuleer"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardButtonGroup from '@/components/button/WizardButtonGroup.vue';
import TextButton from '@/components/button/TextButton.vue';
import axios from 'axios';
import { DeleteDialogObject } from '@/domain/interface/DeleteDialogObject';

@Component({
  components: {
    TextButton,
    WizardButtonGroup,
  },
})
export default class DeleteDialog extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop(String) title!: string;
  @Prop(String) content!: string;
  @Prop(String) deleteText!: string;
  @Prop(Object) readonly deleteObject!: DeleteDialogObject;

  get dialogTitle() {
    if (this.deleteObject.naam) {
      return `${this.$t(this.title)} ${this.deleteObject.naam}`;
    } else {
      return `${this.$t(this.title)}`;
    }
  }

  get dialogContent() {
    return this.$t(this.content);
  }

  verwijder() {
    if (this.deleteObject?.links?.verwijderen) {
      axios
        .delete(this.deleteObject.links.verwijderen)
        .then(() => {
          this.$emit('on-deleted');
        })
        .catch((error) => {
          this.$emit('on-error', error.response.data?.errors);
        })
        .finally(() => this.$emit('close'));
    } else {
      this.$emit('delete');
    }
  }

  annuleer() {
    this.$emit('annuleer');
  }
}
</script>
