<template>
  <v-snackbar
    v-model="messages"
    :timeout="timeout"
    top
    right
    text
    min-width="auto"
  >
    <v-alert class="pa-0, ma-0" text type="success">
      <v-row v-for="(message, index) in messages" :key="index" align="center">
        <v-col> {{ message.message }}</v-col>
      </v-row>
    </v-alert>
  </v-snackbar>
</template>

<!--We override some default stying from snackbar - de content padding en margin caused extra white spacing-->
<style>
.v-snack__content {
  background-color: transparent;
  padding: 0;
}

.v-application--is-ltr .v-snack__action {
  margin-right: 0;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconButton from '@/components/button/IconButton.vue';
import { namespace } from 'vuex-class';
import { SuccesMessage } from '@/store/modules/successAlertMessages';

const SuccessAlertMessages = namespace('SuccessAlertMessages');

@Component({
  components: { IconButton },
})
export default class SuccessAlert extends Vue {
  private readonly timeout = 3000;

  created() {
    setTimeout(() => {
      this.updateMessages([]);
    }, this.timeout);
  }

  @Prop() readonly messages!: [];

  @SuccessAlertMessages.Action
  public updateMessages!: (messages: SuccesMessage[]) => void;
}
</script>