export default class ConfigHelper {
  public static getBasisRegistersUrl(): string {
    return process.env.VUE_APP_BASE_URL_BASISREGISTERS_VLAANDEREN;
  }

  public static getUrlGemeenten(): string {
    return '/gemeenten';
  }

  public static getBasisRegistersUrlStraatnamen(): string {
    return this.getBasisRegistersUrl() + '/straatnamen?limit=500';
  }

  public static getBasisRegistersUrlAdressen(): string {
    return this.getBasisRegistersUrl() + '/adressen?limit=500';
  }
}
