<template>
  <div style="width: 100%">
    <info-card
      :datum-ingediend="activiteit.datumIngediend"
      :items="items"
      :menu-items="menuItems"
      @click-item="clickItem"
    />

    <activiteit-delete-dialog
      v-model="showDeleteDialog"
      :activiteit="activiteit"
      @on-deleted="onDelete"
      @close="sluitDialoog"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import InfoCard from '@/components/card/InfoCard.vue';
import { MenuItemInterface } from '@/domain/interface/intern/MenuItemInterface';
import { ActiviteitDetailClass } from '@/domain/interface/activiteit/ActiviteitDetailClass';
import ActiviteitDeleteDialog from '@/components/dialog/ActiviteitDeleteDialog.vue';

@Component({
  components: { ActiviteitDeleteDialog, InfoCard },
})
export default class ActiviteitCard extends Vue {
  @Prop(Object) readonly activiteit!: ActiviteitDetailClass;
  @Provide() showDeleteDialog = false;

  get items() {
    return [
      {
        label: this.$t('activiteit.status'),
        value: this.activiteit?.activiteitStatus?.omschrijving,
      },
      {
        label: this.$t('activiteit.nummer'),
        value: this.activiteit?.meldingsnummer,
      },
      {
        label: this.$t('activiteit.activiteit-type'),
        value: this.activiteit?.activiteitType?.omschrijving,
      },
      {
        label: this.$t('activiteit.adres'),
        value: this.activiteit?.gebouw?.volledigAdres,
      },
      {
        label: this.$t('activiteit.datum-plaatsbezoek'),
        value:
          this.$options.filters?.formatDate_ddmmyyyy_slash(
            this.activiteit?.datumActiviteit
          ) || 'undefined',
      },
    ];
  }

  get menuItems(): MenuItemInterface[] {
    const items = [];
    if (this.activiteit.links?.detail) {
      items.push({
        label: this.$t('acties.bekijken'),
        to: {
          name: 'activiteitLandingPage',
          params: {
            activiteitUuid: this.activiteit?.id || 'undefined',
          },
        },
        action: this.$t('acties.bekijken').toString(),
      });
    }

    if (this.activiteit.links?.verwijderen) {
      items.push({
        label: this.$t('acties.verwijderen'),
        to: {},
        action: this.$t('acties.verwijderen').toString(),
      });
    }
    return items;
  }

  clickItem(item: MenuItemInterface) {
    if (
      item.action === this.$t('acties.verwijderen') &&
      this.activiteit?.links?.verwijderen
    ) {
      this.showDeleteDialog = true;
    } else {
      this.$emit('click-item', item);
    }
  }

  sluitDialoog() {
    this.showDeleteDialog = false;
  }

  onDelete() {
    this.sluitDialoog();
    this.$emit('delete-item');
  }
}
</script>
