<template>
  <div style="min-width: 500px; margin: auto; font-size: 80%">
    <span v-if="envIsLeeromgeving" style="color: red">
      {{ $t('home.leeromgeving') }}
      <a style="color: inherit" href="https://melding.veka.vlaanderen.be"
        >melding.veka.vlaanderen.be</a
      >
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { envIsLeeromgeving } from '@/main';

@Component
export default class Leeromgeving extends Vue {
  envIsLeeromgeving = false;
  created() {
    this.envIsLeeromgeving = envIsLeeromgeving;
  }
}
</script>
