<template>
  <v-app-bar app color="white" fixed height="80">
    <div class="d-flex align-center header-image-box">
      <vlaanderen-logo class="header-image" />
    </div>
    <div class="ml-7">
      <router-link class="text-decoration-none black--text" to="/">
        <div>
          <h4>
            {{ getTitle }}
            <span v-if="envIsLeeromgeving" class="font-weight-bold"
              >leeromgeving</span
            >
          </h4>
        </div>
      </router-link>
      <leeromgeving />
    </div>

    <v-spacer />
    <v-skeleton-loader v-if="loading" class="flex" elevation="2" type="text" />
    <template v-else>
      <template v-if="isLoggedIn">
        <avatar />
      </template>

      <router-link to="/" v-if="!isLoggedIn"
        >{{ $t('links.aanmelden') }}
      </router-link>
    </template>
  </v-app-bar>
</template>

<script lang="ts">
import { Component, Provide, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VlaanderenLogo from '@/components/layout/VlaanderenLogo.vue';
import AuthorizationService from '@/services/AuthorizationService';
import { envIsLeeromgeving } from '@/main';
import Avatar from '@/components/avatar/Avatar.vue';
import Leeromgeving from '@/components/title/Leeromgeving.vue';

const auth = new AuthorizationService();

const HeaderStore = namespace('Header');
const Gebruiker = namespace('Gebruiker');

@Component({
  components: { Leeromgeving, Avatar, VlaanderenLogo },
})
export default class Header extends Vue {
  @Provide() isLoggedIn = false;
  @Provide() loading = false;

  envIsLeeromgeving = false;

  created() {
    this.envIsLeeromgeving = envIsLeeromgeving;
    if (process.env.NODE_ENV === 'development') {
      this.logInAndUpdateGebruiker();
    } else {
      auth.isLoggedIn().then(() => this.logInAndUpdateGebruiker());
    }
  }

  logInAndUpdateGebruiker() {
    this.loading = true;
    this.isLoggedIn = true;
    this.updateGebruiker().finally(() => (this.loading = false));
  }

  @Gebruiker.Action
  public updateGebruiker!: () => Promise<void>;

  @HeaderStore.Getter
  public getTitle!: () => string;
}
</script>
