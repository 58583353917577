<template>
  <v-row class="text-center">
    <v-col cols="12">
      <v-progress-circular
        :size="size"
        :width="width"
        :color="color"
        indeterminate
      />
    </v-col>
    <v-col cols="12">
      {{ text }}
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ProgressCircleRow extends Vue {
  @Prop({ default: 'Aan het laden...' }) readonly text!: string;
  @Prop({ default: '70' }) readonly size!: string;
  @Prop({ default: '7' }) readonly width!: string;
  @Prop({ default: 'primary' }) readonly color!: string;
}
</script>
