<template>
  <div>
    <v-row>
      <v-col align="right">
        <primary-button v-if="isInRolControleur" @click="navNaarBeheerscherm">
          {{ $t('home.beheer-scherm') }}
        </primary-button>
      </v-col>
    </v-row>
    <div style="max-width: 900px; margin: auto">
      <subtitle :title="$t('home.subtitle')" />
      <v-row>
        <v-col cols="12" lg="5">
          <v-card class="d-flex flex-column" height="100%">
            <v-card-title>
              {{ $t('home.stookolie-title') }}
            </v-card-title>
            <v-spacer />
            <v-card-actions class="justify-center">
              <primary-button :disabled="envIsLeeromgeving" @click="navToMeldStookoliePlaatsing" id="stookolie-plaatsing-button">
                {{ $t('home.ga-verder') }}
              </primary-button>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-spacer />
        <v-col cols="12" lg="5">
          <v-card class="d-flex flex-column" height="100%">
            <v-card-title>
              {{ $t('home.activiteit-title') }}
            </v-card-title>
            <v-spacer />
            <v-card-actions class="justify-center">
              <primary-button v-if="activiteitTileDisabled" disabled>
                {{ $t('home.ga-verder') }}
              </primary-button>
              <primary-button v-else @click="navToActiviteitOverzicht" id="activiteit-button">
                {{ $t('home.ga-verder') }}
              </primary-button>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PrimaryButton from '@/components/button/PrimaryButton.vue';
import ActiviteitCard from '@/components/card/ActiviteitCard.vue';
import AuthorizationService from '@/services/AuthorizationService';
import { namespace } from 'vuex-class';
import { LocaleMessage } from 'vue-i18n';
import Subtitle from '@/components/title/Subtitle.vue';
import { envIsLeeromgeving } from '@/main';

const Loader = namespace('Loader');
const Header = namespace('Header');
const Activiteit = namespace('Activiteit');

const auth = new AuthorizationService();

@Component({
  components: {
    ActiviteitCard,
    PrimaryButton,
    Subtitle,
  },
})
export default class Home extends Vue {
  isInRolControleur = false;
  activiteitTileDisabled = false;
  envIsLeeromgeving = false;

  created() {
    this.envIsLeeromgeving = envIsLeeromgeving;
    this.updateTitle(this.$t('app.title-main'));

    if (!this.isLocal()) {
      auth.isLoggedIn().then((loggedIn) => {
        if (!loggedIn) {
          auth.login();
        }
      });
    }

    this.updateLoaderLoading('');
    this.updateLoaderDone();

    if (this.isLocal()) {
      this.isInRolControleur = true;
    } else {
      auth.isInRolControleur().then((isControleur) => {
        this.isInRolControleur = isControleur;
      });
    }

    // Reset the Activiteit store.
    this.resetStatus();
    // Reset readonly, must be defined for each call to activiteit.
    this.updateReadOnly(false);
  }

  isLocal(): boolean {
    return process.env.NODE_ENV === 'development';
  }

  navToMeldStookoliePlaatsing() {
    this.updateTitle(this.$t('app.title-stookolie'));
    this.$router.push({ name: 'meldStookoliePlaatsing' });
  }

  navToActiviteitOverzicht() {
    if (!this.activiteitTileDisabled) {
      this.updateTitle(this.$t('app.title-activiteit'));
      this.$router.push({ name: 'activiteit-overzicht' });
    }
  }

  navNaarBeheerscherm() {
    this.$router.push({ name: 'beheerscherm' });
  }

  @Loader.Action
  public updateLoaderLoading!: (message?: string | LocaleMessage) => void;

  @Loader.Action
  public updateLoaderDone!: () => void;

  @Header.Action
  public updateTitle!: (title: string | LocaleMessage) => void;

  @Activiteit.Action
  public resetStatus!: () => void;

  @Activiteit.Action
  public updateReadOnly!: (readOnly: boolean) => void;
}
</script>
