<template>
  <v-snackbar v-model="errors" top color="error">
    <v-alert type="error" prominent dense>
      <v-row v-for="(error, index) in errors" :key="index" align="center">
        <v-col> {{ error.message }}</v-col>
        <v-col cols="auto">
          <icon-button icon="mdi-close" @click="errors.splice(index)" />
        </v-col>
      </v-row>
    </v-alert>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconButton from '@/components/button/IconButton.vue';
import { namespace } from 'vuex-class';
import { ErrorInterface } from '@/store/modules/errors';

const Errors = namespace('Errors');

@Component({
  components: { IconButton },
})
export default class Alert extends Vue {
  created() {
    setTimeout(() => {
      this.updateErrors([]);
    }, 5000);
  }

  @Prop() readonly errors!: [];

  @Errors.Action
  public updateErrors!: (errors: ErrorInterface[]) => void;
}
</script>
