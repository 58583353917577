<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { VBtn } from 'vuetify/lib';

@Component
export default class SecondaryButton extends VBtn {
  @Prop({ default: 'primary' }) readonly color!: string;
  @Prop({ default: true }) readonly outlined!: boolean;
}
</script>
