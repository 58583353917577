<template>
  <v-app>
    <Header />

    <v-main>
      <breadcrumbs />
      <alert v-if="errors.length > 0" :errors.sync="errors" />
      <SuccessAlert v-if="messages.length > 0" :messages.sync="messages" />
      <warningAlert v-if="warnings.length > 0" :warnings.sync="warnings" />
      <v-container style="background: rgb(249 249 249)">
        <router-view />
      </v-container>
      <v-overlay :value="getLoading" style="z-index: 9999">
        <progress-circle-row
          :text="getLoadingMessage"
          size="64"
          color="white"
          width="5"
        />
      </v-overlay>
    </v-main>

    <Footer id="footer-border-top" />
  </v-app>
</template>

<script lang="ts">
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import {Component, Provide, Vue} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue';
import ProgressCircleRow from '@/components/progress/ProgressCircleRow.vue';
import Alert from '@/components/layout/Alert.vue';
import SuccessAlert from '@/components/layout/SuccessAlert.vue';
import WarningAlert from '@/components/layout/WarningAlert.vue';
import NotificationService from '@/services/NotificationService';

const Loader = namespace('Loader');
const Errors = namespace('Errors');
const SuccessAlertMessages = namespace('SuccessAlertMessages');
const WarningAlertMessages = namespace('WarningAlertMessages');

@Component({
  components: {
    WarningAlert,
    Alert,
    SuccessAlert,
    ProgressCircleRow,
    Header,
    Footer,
    Breadcrumbs,
  },
})
export default class App extends Vue {
  @Provide('notificationService')
  notificationService: NotificationService = new NotificationService();
  created() {
    //default app name in package.json veranderen naar een user friendly titel
    document.title = this.$t('app.title-main').toString();
  }

  @Loader.Getter
  public getLoading!: boolean;

  @Loader.Getter
  public getLoadingMessage!: string;

  @Errors.Getter('getErrors')
  public errors!: string[];

  @SuccessAlertMessages.Getter('getMessages')
  public messages!: string[];

  @WarningAlertMessages.Getter('getWarnings')
  public warnings!: string[];
}
</script>