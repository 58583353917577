<template>
  <v-row>
    <v-col>
      <h2 class="pt-5">
        {{ title }}
        <slot name="suffix" />
      </h2>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Subtitle extends Vue {
  @Prop(String) readonly title: string | undefined;
}
</script>
