<template>
  <v-menu
    v-if="items.length > 0"
    bottom
    origin="center center"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-bind="buttonAttrs || { icon: true }" v-on="on">
        <slot name="buttonContent">
          <v-icon>mdi-dots-vertical</v-icon>
        </slot>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        link
        @click="clickItem(item)"
      >
        <v-list-item-title>{{ item.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MenuItemInterface } from '@/domain/interface/intern/MenuItemInterface';

@Component
export default class MenuButton extends Vue {
  @Prop() readonly items: MenuItemInterface[] | undefined;
  @Prop() readonly buttonAttrs: object | undefined;

  clickItem(item: MenuItemInterface) {
    this.$emit('click-item', item);
  }
}
</script>
