<template>
    <a
      :href="href"
      target="_blank"
      class="mx-md-1"
    >{{ content }}</a>
</template>



<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FooterLink extends Vue {
  @Prop(String) href!: string;
  @Prop(String) content!: string;
}
</script>
