<template>
  <v-menu bottom min-width="200px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon x-large v-on="on">
        <v-avatar color="primary" size="40">
          <span class="white--text text-h6">{{ initialen }}</span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <v-avatar color="primary">
            <span class="white--text text-h5">{{ initialen }}</span>
          </v-avatar>
          <h3>{{ volledigeNaam }}</h3>
          <v-divider class="my-3" />

          <template v-if="registratierollen">
            {{ registratierollen }}
            <v-divider class="my-3" />
          </template>

          <template v-if="erkenningen">
            Erkende technicus
            <div v-for="erkenning in erkenningen.split(',')" :key="erkenning">
              <v-divider class="my-3" />
              {{ erkenning }}
            </div>
          </template>

          <v-divider class="my-3" />
          <v-btn depressed rounded @click="afmelden"
            >{{ $t('links.afmelden') }}
          </v-btn>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';

const Gebruiker = namespace('Gebruiker');

@Component
export default class Avatar extends Vue {
  afmelden() {
    this.$router.push('/afmelden');
  }

  @Gebruiker.Getter
  public volledigeNaam!: string;

  @Gebruiker.Getter
  public rrn!: string;

  @Gebruiker.Getter
  public registratierollen!: string;

  @Gebruiker.Action
  public updateGebruiker!: () => Promise<void>;

  @Gebruiker.Getter
  public erkenningen!: string;

  @Gebruiker.Getter
  public initialen!: string;
}
</script>
