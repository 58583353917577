<template>
  <v-card
    ripple
    width="100%"
    class="flex-column d-flex"
    @click="$emit('click', $event)"
  >
    <v-card-title>
      <v-row dense>
        <v-col class="text-truncate text-align-end">
          <span class="font-italic text-caption">{{ datumIngediendText }}</span>
        </v-col>
        <MenuButton :items="menuItems" @click-item="clickItem" />
      </v-row>
    </v-card-title>
    <v-card-text class="flex-column">
      <v-row v-for="(item, index) in items" :key="index" dense>
        <v-col v-if="item.label" class="font-weight-bold"
          >{{ item.label }}
        </v-col>
        <v-col v-if="item.value">{{ item.value }}</v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MenuItemInterface } from '@/domain/interface/intern/MenuItemInterface';
import MenuButton from '@/components/menu/MenuButton.vue';

@Component({ components: { MenuButton } })
export default class InfoCard extends Vue {
  @Prop() readonly datumIngediend: string | undefined;
  @Prop() readonly items: object[] | undefined;
  @Prop(Array) readonly menuItems!: MenuItemInterface[];

  get datumIngediendText() {
    if (this.datumIngediend) {
      return `Ingediend op ${this.$options.filters?.formatDate_ddmmyyyy_slash(
        this.datumIngediend
      )}`;
    }
    return '';
  }

  clickItem(item: object) {
    this.$emit('click-item', item);
  }
}
</script>
