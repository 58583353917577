import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { GebruikerInterface } from '@/domain/interface/GebruikerInterface';
import AuthorizationService from '@/services/AuthorizationService';
import axios from 'axios';
import { ErkenningDto } from '@/domain/interface/erkenning/ErkenningDto';

const auth = new AuthorizationService();

@Module({ namespaced: true })
class Gebruiker extends VuexModule {
  url = '/erkenningen';
  public gebruiker: GebruikerInterface = {
    erkenningen: [],
    voornaam: '',
    naam: '',
    registratierol: '',
    rrn: '',
  };

  @Mutation
  public setGebruiker(gebruiker: GebruikerInterface): void {
    this.gebruiker = gebruiker;
  }

  @Action
  async updateGebruiker(): Promise<void> {
    auth.getUser().then((user) => {
      let rol = '';
      const erkenningenLijstObject: string[] = [];
      if (user?.profile.rrn) {
        axios.post(this.url, { rrn: user?.profile.rrn }).then(({ data }) => {
          data.forEach((value: ErkenningDto) => {
            if (value.erkenningsnummer) {
              erkenningenLijstObject.push(value.erkenningsnummer);
            }
          });
        });
      }

      auth
        .isInRolControleur()
        .then((isControleur) => {
          if (isControleur) {
            rol = 'VEKA medewerker';
          }
        })
        .finally(() => {
          this.context.commit('setGebruiker', {
            voornaam: user?.profile.given_name,
            naam: user?.profile.family_name,
            registratierol: rol,
            rrn: user?.profile.rrn,
            erkenningen: erkenningenLijstObject,
          });
        });
    });
  }

  get voornaam(): string {
    return this.gebruiker.voornaam;
  }

  get naam(): string {
    return this.gebruiker.naam;
  }

  get volledigeNaam(): string {
    return this.gebruiker.naam + ', ' + this.gebruiker.voornaam;
  }

  get erkenningen(): string {
    return this.gebruiker.erkenningen.join(', ');
  }

  get registratierollen(): string {
    return this.gebruiker.registratierol;
  }

  get rrn(): string {
    return this.gebruiker.rrn;
  }

  get initialen(): string {
    return `${this.gebruiker.naam ? this.gebruiker.naam.charAt(0) : ''}${this.gebruiker.voornaam ? this.gebruiker.voornaam.charAt(0) : ''}`;
  }
}

export default Gebruiker;
