<template>
  <v-snackbar v-model="warnings" top color="warning">
    <v-alert
        type="warning"
        prominent
        dense
        @mouseover="keepAlertOpen"
        @mouseleave="canCloseAlert"
        style="cursor: default"
    >
      <v-row v-for="(warning, index) in warnings" :key="index" align="center">
        <v-col>
          <p v-for="(line, index) in warning.split('\n')" :key="index">
            {{ line }}
          </p>
        </v-col>
        <v-col cols="auto">
          <icon-button icon="mdi-close" @click="warnings.splice(index)" />
        </v-col>
      </v-row>
    </v-alert>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import IconButton from '@/components/button/IconButton.vue';
import NotificationService from '@/services/NotificationService';

@Component({
  components: { IconButton },
})
export default class WarningAlert extends Vue {
  @Inject('notificationService') notificationService!: NotificationService;
  private readonly timeout = 5000;
  private keepAlertOpenActive = false;
  private delayForClosurePassed = false;

  created() {
    setTimeout(() => {
      if (!this.keepAlertOpenActive) {
        this.notificationService.resetWarnings();
      }
      this.delayForClosurePassed = true;
    }, this.timeout);
  }

  keepAlertOpen(): void {
    this.keepAlertOpenActive = true;
  }

  canCloseAlert(): void {
    if (this.delayForClosurePassed) {
      this.notificationService.resetWarnings();
    } else {
      this.keepAlertOpenActive = false;
    }
  }

  @Prop() readonly warnings!: [];
}
</script>