<template>
  <span class='d-md-block d-none'>|</span>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Separator extends Vue{}
</script>
